<template>
  <div class="Coupons__main-container">
    <h3 class="Coupons__title">Superuser Coupons</h3>
    <div class="Coupons__center-container">
      <div class="AdminTable__search">
        <div class="AdminTable__search-buttons">
          <button
            class="AdminTable__table-cell-button"
            @click="createSuperuserCoupon()"
          >
            Generate coupon
          </button>
        </div>
      </div>
      <div class="AdminTable__table-header">
        <div class="AdminTable__table-header-cell Grid-column-6">Code</div>
        <div class="AdminTable__table-header-cell--justify-start Grid-column-6">
          Status
        </div>
      </div>
      <div
        v-for="coupon in superuserCouponsList"
        :key="coupon.code"
        class="AdminTable__table-row"
      >
        <mobile-row :row-data="getMobileRowData(coupon)" />
        <div class="AdminTable__table-cell Grid-column-6">
          {{ coupon.code }}
        </div>
        <div class="AdminTable__table-cell Grid-column-6">
          {{ coupon.status }}
        </div>
      </div>
      <infinite-loading spinner="spiral" @infinite="infiniteHandler">
        <div slot="no-more">
          <div class="AdminTable__no-more">No more results to show.</div>
        </div>
        <div slot="no-results">
          <div class="AdminTable__no-more">No more results to show.</div>
        </div>
      </infinite-loading>
    </div>
  </div>
</template>
<script>
import InfiniteLoading from 'vue-infinite-loading';
import { mapGetters, mapActions } from 'vuex';
import MobileRow from '@/components/utils/MobileRow.vue';
export default {
  name: 'Coupons',
  components: {
    InfiniteLoading,
    MobileRow,
  },

  data: () => ({
    isLoading: false,
  }),
  created() {
    this.clearSuperuserCouponsData();
  },

  computed: {
    ...mapGetters(['superuserCouponsList']),
  },

  methods: {
    ...mapActions([
      'getSuperuserCoupons',
      'clearSuperuserCouponsData',
      'createSuperuserCoupon',
    ]),
    getMobileRowData(coupon) {
      return [
        {
          title: 'Code',
          text: coupon.code,
        },
        { title: 'Status', text: coupon.status },
      ];
    },
    infiniteHandler($state) {
      if (this.isLoading) {
        return;
      }
      this.isLoading = true;
      this.getSuperuserCoupons().then((data) => {
        this.isLoading = false;
        $state.loaded();
        if (data.coupon && !data.nextToken) {
          $state.complete();
        }
      });
    },
  },
};
</script>
<style lang="scss" scoped>
@import '@/assets/stylesheets/components/coupons/Coupons.scss';
</style>
